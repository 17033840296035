import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import ReactLoading from "react-loading";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardAvatar from "../../components/Card/CardAvatar.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import "./Styles.css";
import { toast } from "react-toastify";
import customInputStyle from "../../assets/jss/material-dashboard-react/components/buttonStyle";
import Select from "react-select";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import Web3 from "web3";
import config from "../../lib/config";
import ICOABI from "../../ABI/ICOABI.json";
import { updateDatesAction, getUpdatedDates } from "../../actions/admin";
import Switch from "react-switch";
import moment from "moment";
import "react-phone-input-2/lib/style.css";

//import avatar from "assets/img/faces/marc.jpg";
import isEmpty from "../../lib/isEmpty";

import { SetClaimStatus, getClaimSetting } from "../../actions/admin";
import { toastAlert } from "../../lib/toastAlert.js";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "16px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    // getadminUsers,
    textDecoration: "none",
  },
};

// toaster config
toast.configure();
let toasterOption = {
  position: "top-right",
  autoClose: 4000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const initialFormValue = {
  question: "",
  answer: "",
};

const useStyles = makeStyles(styles);

const customStyle = makeStyles(customInputStyle);

export default function UpdateDates() {
  const customStyles = customStyle();

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [autoRenewal, setAutoRenewal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [time1, setTime1] = useState("");
  const [time2, setTime2] = useState("");
  const [time3, setTime3] = useState("");
  const [time4, setTime4] = useState("");
  const [time5, setTime5] = useState("");
  const [time6, setTime6] = useState("");
  const [time7, setTime7] = useState("");
  const [time8, setTime8] = useState("");
  const [showtime1, setshowtime1] = useState("");
  const [showtime2, setshowtime2] = useState("");
  const [showtime3, setshowtime3] = useState("");
  const [showtime4, setshowtime4] = useState("");
  const [showtime5, setshowtime5] = useState("");
  const [showtime6, setshowtime6] = useState("");
  const [showtime7, setshowtime7] = useState("");
  const [showtime8, setshowtime8] = useState("");
  const [address, setaddress] = useState("");
  const [chainID, setchainID] = useState("");
  const [currentStatge, setcurrentStatge] = useState(0);

  const acc = async () => {
    const web3 = new Web3(window.ethereum);
    let accounts = await web3.eth.requestAccounts();
    let chainid = await web3.eth.getChainId();
    // console.log(chainid, accounts[0], "accountsaccounts===");
    setaddress(accounts[0]);
    setchainID(chainid);
    var ICOcontract = new web3.eth.Contract(ICOABI, config.singlesmartContract);
    var currentstage = await ICOcontract.methods.getStage().call();
    // console.log(currentstage, "currentstage");
    setcurrentStatge(parseInt(currentstage));
  };

  const handleFormSubmit = async () => {
    setLoader(true);
    var web3 = new Web3(window.ethereum);
    var accounts = await web3.eth.requestAccounts();
    let chainid = await web3.eth.getChainId();

    if (chainid == config.networkID) {
      var ICOcontract = new web3.eth.Contract(
        ICOABI,
        config.singlesmartContract
      );
      var addmin_add = await ICOcontract.methods.owner().call({});
      // console.log(addmin_add, "addmin_add");

      if (accounts[0] == addmin_add) {
        if (time1 == "" || time1 == undefined) {
          toastAlert("error", "Enter start time 1");
          setLoader(false);
          return false;
        } else if (time2 == "" || time2 == undefined) {
          toastAlert("error", "Enter start time 2");
          setLoader(false);
          return false;
        } else if (time3 == "" || time3 == undefined) {
          toastAlert("error", "Enter start time 3");
          setLoader(false);
          return false;
        } else if (time4 == "" || time4 == undefined) {
          toastAlert("error", "Enter start time 4");
          setLoader(false);
          return false;
        } else if (time5 == "" || time5 == undefined) {
          toastAlert("error", "Enter start time 5");
          setLoader(false);
          return false;
        } else if (time6 == "" || time6 == undefined) {
          toastAlert("error", "Enter start time 6");
          setLoader(false);
          return false;
        } else if (time7 == "" || time7 == undefined) {
          toastAlert("error", "Enter start time 7");
          setLoader(false);
          return false;
        } else if (time8 == "" || time8 == undefined) {
          toastAlert("error", "Enter start time 8");
          setLoader(false);
          return false;
        }
        // console.log(new Date(parseInt(time1)), "fsvfdrsvgf");
        const v1 = await DateValidate(time1);

        if (await DateValidate(time1)) {
          toastAlert("error", `Invalid Date 1`);
          setLoader(false);
          return false;
        }
        if (await DateValidate(time2)) {
          toastAlert("error", `Invalid Date 2`);
          setLoader(false);
          return false;
        }
        if (await DateValidate(time3)) {
          toastAlert("error", `Invalid Date 3`);
          setLoader(false);
          return false;
        }
        if (await DateValidate(time4)) {
          toastAlert("error", `Invalid Date 4`);
          setLoader(false);
          return false;
        }
        if (await DateValidate(time5)) {
          toastAlert("error", `Invalid Date 5`);
          setLoader(false);
          return false;
        }
        if (await DateValidate(time6)) {
          toastAlert("error", `Invalid Date 6`);
          setLoader(false);
          return false;
        }
        if (await DateValidate(time7)) {
          toastAlert("error", `Invalid Date 7`);
          setLoader(false);
          return false;
        }
        if (await DateValidate(time8)) {
          toastAlert("error", `Invalid Date 8`);
          setLoader(false);
          return false;
        }

        // var web3 = new Web3(window.ethereum);

        var ICOcontract = new web3.eth.Contract(
          ICOABI,
          config.singlesmartContract
        );

        var currentstage = await ICOcontract.methods.getStage().call();
        // console.log(currentstage, "currentstagecurrentstage");

        if (
          (parseInt(currentstage) == 1 || parseInt(currentstage) == 0) &&
          parseInt(time1) < parseInt(new Date() / 1000)
        ) {
          toastAlert("error", "End time 1 must be greater than now");
          setLoader(false);
          return false;
        }

        // console.log(currentstage, "curreghfnhntstagecurrentstage");

        if (
          parseInt(currentstage) == 2 &&
          parseInt(time2) < parseInt(new Date() / 1000)
        ) {
          toastAlert("error", "End time 2 must be greater than now");
          setLoader(false);
          return false;
        }

        if (
          parseInt(currentstage) == 3 &&
          parseInt(time3) < parseInt(new Date() / 1000)
        ) {
          toastAlert("error", "End time 3 must be greater than now");
          setLoader(false);
          return false;
        }

        if (
          parseInt(currentstage) == 4 &&
          parseInt(time4) < parseInt(new Date() / 1000)
        ) {
          toastAlert("error", "End time 4 must be greater than now");
          setLoader(false);
          return false;
        }

        if (
          parseInt(currentstage) == 5 &&
          parseInt(time5) < parseInt(new Date() / 1000)
        ) {
          toastAlert("error", "End time 5 must be greater than now");
          setLoader(false);
          return false;
        }

        if (
          parseInt(currentstage) == 6 &&
          parseInt(time6) < parseInt(new Date() / 1000)
        ) {
          toastAlert("error", "End time 6 must be greater than now");
          setLoader(false);
          return false;
        }

        if (
          parseInt(currentstage) == 7 &&
          parseInt(time7) < parseInt(new Date() / 1000)
        ) {
          toastAlert("error", "End time 7 must be greater than now");
          setLoader(false);
          return false;
        }

        if (
          parseInt(currentstage) == 8 &&
          parseInt(time8) < parseInt(new Date() / 1000)
        ) {
          toastAlert("error", "End time 8 must be greater than now");
          setLoader(false);
          return false;
        }

        if (
          // parseInt(currentstage) <= 2 &&
          new Date(parseInt(time1)).getTime() >=
          new Date(parseInt(time2)).getTime()
        ) {
          // console.log(
          //   new Date(parseInt(time1)).getTime(),
          //   new Date(parseInt(time2)).getTime(),
          //   "uuuuuuuuu"
          // );
          toastAlert("error", "End time 2 must be greater than end time 1");
          setLoader(false);
          return false;
        }
        if (
          // parseInt(currentstage) <= 3 &&
          new Date(parseInt(time2)).getTime() >=
          new Date(parseInt(time3)).getTime()
        ) {
          toastAlert("error", "End time 3 must be greater than end time 2");
          setLoader(false);
          return false;
        }
        if (
          // parseInt(currentstage) <= 4 &&
          new Date(parseInt(time3)).getTime() >=
          new Date(parseInt(time4)).getTime()
        ) {
          toastAlert("error", "End time 4 must be greater than end time 3");
          setLoader(false);
          return false;
        }
        if (
          // parseInt(currentstage) <= 5 &&
          new Date(parseInt(time4)).getTime() >=
          new Date(parseInt(time5)).getTime()
        ) {
          toastAlert("error", "End time 5 must be greater than end time 4");
          setLoader(false);
          return false;
        }
        if (
          // parseInt(currentstage) <= 6 &&
          new Date(parseInt(time5)).getTime() >=
          new Date(parseInt(time6)).getTime()
        ) {
          toastAlert("error", "End time 6 must be greater than end time 5");
          setLoader(false);
          return false;
        }
        if (
          // parseInt(currentstage) <= 7 &&
          new Date(parseInt(time6)).getTime() >=
          new Date(parseInt(time7)).getTime()
        ) {
          toastAlert("error", "End time 7 must be greater than end time 6");
          setLoader(false);
          return false;
        }
        if (
          // parseInt(currentstage) <= 8 &&
          new Date(parseInt(time7)).getTime() >=
          new Date(parseInt(time8)).getTime()
        ) {
          toastAlert("error", "End time 8 must be greater than end time 7");
          setLoader(false);
          return false;
        }

        if (window.ethereum) {
          setLoader(true);
          // var web3 = new Web3(window.ethereum);

          var ICOcontract = new web3.eth.Contract(
            ICOABI,
            config.singlesmartContract
          );

          var gasLimit = await ICOcontract.methods
            .updateDate(
              time1.toString(),
              time2.toString(),
              time3.toString(),
              time4.toString(),
              time5.toString(),
              time6.toString(),
              time7.toString(),
              time8.toString()
            )
            .estimateGas({
              from: accounts[0],
            });
          gasLimit = gasLimit + 100000;
          var gasPrice = await web3.eth.getGasPrice();

          await ICOcontract.methods
            .updateDate(
              time1.toString(),
              time2.toString(),
              time3.toString(),
              time4.toString(),
              time5.toString(),
              time6.toString(),
              time7.toString(),
              time8.toString()
            )
            .send({ from: accounts[0], gas: gasLimit, gasPrice: gasPrice })
            .then(async (result) => {
              // console.log(result, "resultresult=====");
              const payload = {
                hash: result.transactionHash,
                time1: showtime1,
                time2: showtime2,
                time3: showtime3,
                time4: showtime4,
                time5: showtime5,
                time6: showtime6,
                time7: showtime7,
                time8: showtime8,
              };

              const data = await updateDatesAction(payload);
              if (data.status) {
                toastAlert("success", data.message);
                setTimeout(() => {
                  window.location.reload();
                }, 2000);
                setLoader(false);
              } else {
                toastAlert("error", data.message);
                setLoader(false);
              }
            })
            .catch((err) => {
              console.log(err, "errr");
              setLoader(false);
            });
        }
      } else {
        toastAlert("error", "Connect owner wallet");
        setLoader(false);
        return false;
      }
    } else {
      toastAlert("error", `Connect ${config.Symbol} network`);
      setLoader(false);
      return false;
    }
  };

  useEffect(() => {
    acc();
    getDateUpdate();
    getData();
  }, []);

  const getDateUpdate = async () => {
    const data = await getUpdatedDates();
    // console.log(data, "datadatadatadatadatadata");
    setshowtime1(data?.result?.time1);
    const datatime1 = moment(data?.result?.time1).unix();
    setTime1(datatime1);
    setshowtime2(data?.result?.time2);
    const datatime2 = moment(data?.result?.time2).unix();
    setTime2(datatime2);
    setshowtime3(data?.result?.time3);
    const datatime3 = moment(data?.result?.time3).unix();
    setTime3(datatime3);
    setshowtime4(data?.result?.time4);
    const datatime4 = moment(data?.result?.time4).unix();
    setTime4(datatime4);
    setshowtime5(data?.result?.time5);
    const datatime5 = moment(data?.result?.time5).unix();
    setTime5(datatime5);
    setshowtime6(data?.result?.time6);
    const datatime6 = moment(data?.result?.time6).unix();
    setTime6(datatime6);
    setshowtime7(data?.result?.time7);
    const datatime7 = moment(data?.result?.time7).unix();
    setTime7(datatime7);
    setshowtime8(data?.result?.time8);
    const datatime8 = moment(data?.result?.time8).unix();
    setTime8(datatime8);
  };

  const DateValidate = async (val) => {
    const res = new Date(parseInt(val));
    // console.log(res, "fsvfdrsvgf");
    if (res == "Invalid Date") {
      return true;
    } else {
      return false;
    }
  };
  const getData = async () => {
    let { result } = await getClaimSetting();
    // console.log(result?.Data[0]?.status, "22222");
    if (result?.Data[0]) {
      setAutoRenewal(result?.Data[0]?.status);
    }
  };

  const onChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    // console.log(name, value, "name, value");
    if (name == "time1") {
      const datatime = moment(value).unix();

      setTime1(datatime);
      setshowtime1(value);
    } else if (name == "time2") {
      const datatime = moment(value).unix();
      setTime2(datatime);
      setshowtime2(value);
    } else if (name == "time3") {
      const datatime = moment(value).unix();
      setTime3(datatime);
      setshowtime3(value);
    } else if (name == "time4") {
      const datatime = moment(value).unix();
      setTime4(datatime);
      setshowtime4(value);
    } else if (name == "time5") {
      const datatime = moment(value).unix();
      setTime5(datatime);
      setshowtime5(value);
    } else if (name == "time6") {
      const datatime = moment(value).unix();
      setTime6(datatime);
      setshowtime6(value);
    } else if (name == "time7") {
      const datatime = moment(value).unix();
      setTime7(datatime);
      setshowtime7(value);
    } else if (name == "time8") {
      const datatime = moment(value).unix();
      setTime8(datatime);
      setshowtime8(value);
    }
  };

  return (
    // formData.map(formData => ()
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Update stage end dates</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                  <label style={{ marginTop: "50px", fontSize: "16px" }}>
                    Stage 1 end date
                  </label>
                  <CustomInput
                    type="datetime-local"
                    onChange={onChange}
                    name="time1"
                    value={showtime1}
                    // disabled={currentStatge > 1 ? true : ""}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <label style={{ marginTop: "50px", fontSize: "16px" }}>
                    Stage 2 end date
                  </label>
                  <CustomInput
                    type="datetime-local"
                    onChange={onChange}
                    name="time2"
                    value={showtime2}
                    // disabled={currentStatge > 2 ? true : ""}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <label style={{ marginTop: "50px", fontSize: "16px" }}>
                    Stage 3 end date
                  </label>
                  <CustomInput
                    type="datetime-local"
                    onChange={onChange}
                    value={showtime3}
                    // disabled={currentStatge > 3 ? true : ""}
                    name="time3"
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <label style={{ marginTop: "50px", fontSize: "16px" }}>
                    Stage 4 end date
                  </label>
                  <CustomInput
                    type="datetime-local"
                    onChange={onChange}
                    value={showtime4}
                    // disabled={currentStatge > 4 ? true : ""}
                    name="time4"
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                  <label style={{ marginTop: "50px", fontSize: "16px" }}>
                    Stage 5 end date
                  </label>
                  <CustomInput
                    type="datetime-local"
                    onChange={onChange}
                    value={showtime5}
                    // disabled={currentStatge > 5 ? true : ""}
                    name="time5"
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <label style={{ marginTop: "50px", fontSize: "16px" }}>
                    Stage 6 end date
                  </label>
                  <CustomInput
                    type="datetime-local"
                    onChange={onChange}
                    value={showtime6}
                    // disabled={currentStatge > 6 ? true : ""}
                    name="time6"
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <label style={{ marginTop: "50px", fontSize: "16px" }}>
                    Stage 7 end date
                  </label>{" "}
                  <CustomInput
                    type="datetime-local"
                    onChange={onChange}
                    value={showtime7}
                    // disabled={currentStatge > 7 ? true : ""}
                    name="time7"
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <label style={{ marginTop: "50px", fontSize: "16px" }}>
                    Stage 8 end date
                  </label>
                  <CustomInput
                    type="datetime-local"
                    onChange={onChange}
                    value={showtime8}
                    // disabled={currentStatge > 8 ? true : ""}
                    name="time8"
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button color="primary" type="submit" onClick={handleFormSubmit}>
                Submit
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      {loader == true && (
        <ReactLoading type={"spokes"} color={"#000000"} className="loading" />
      )}
    </div>
  );
}
