let key = {};

let Envname = "production";

if (Envname === "production") {
  console.log("Set demo Config===");
  const API_URL = "https://api.angelpupp.com"; //DEMO
  key = {
    baseUrl: `${API_URL}`,
    singlesmartContract: "0x5bb2250E64066121679900B1174702Ce4640f5dD", //live
    adminAddr: "0xD904EFd7d1a7dD830d82e416444dd60674298E2A",
    networkID: 43114,
    rpcUrl: "https://api.avax.network/ext/bc/C/rpc",
    HashLink: "https://snowtrace.io/tx/",
    Symbol: "AVAX",
  };
} else if (Envname === "demo") {
  console.log("Set demo Config===");
  const API_URL = "https://angelpupapi.wearedev.team"; //DEMO
  key = {
    baseUrl: `${API_URL}`,
    singlesmartContract: "0x7c201a515ef679d2c7E0a384F037e46c297fA14d", //live
    adminAddr: "0x80Aa595c83430288E3A75F4D03bE15b72385420F",
    networkID: 43113,
    rpcUrl: "https://api.avax-test.network/ext/bc/C/rpc",
    HashLink: "https://testnet.snowtrace.io/tx/",
    Symbol: "AVAX",
  };
} else {
  console.log("Set Development Config");
  const API_URL = "http://localhost";
  key = {
    baseUrl: `${API_URL}:3040`,
    singlesmartContract: "0x7924097BDfC159a1dbe370504023A7b89753Ec44", //demo
    networkID: 43113,
    adminAddr: "0x80Aa595c83430288E3A75F4D03bE15b72385420F",
    rpcUrl: "https://api.avax-test.network/ext/bc/C/rpc",
    HashLink: "https://testnet.snowtrace.io/tx/",
    Symbol: "AVAX",
  };
}

export default key;
